import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router } from '@angular/router';
import { SidebarService } from 'src/app/services/sidebar/sidebar.service';

import { AuthService } from '../../services/auth/auth.service';
import { ChangePasswordComponent } from './change-password/change-password.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
    NgIf,
  ],
})
export class HeaderComponent {
  constructor(
    private sidebarService: SidebarService,
    public authService: AuthService,
    private dialog: MatDialog,
    private router: Router,
  ) {}

  toggleSidebar() {
    // Check if the button click event is registered
    this.sidebarService.toggleSidebar();
    // Check if the visibility state is changing
  }

  openChangePassword() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      data: {
        fn: closeDialog,
      },
    });

    dialogRef.afterClosed().subscribe(() => {});

    function closeDialog() {
      dialogRef.close();
    }
  }

  logout() {
    this.router.navigate(['/auth']).then(() => {
      this.authService.clearUserInfo();
      location.reload();
    });
  }
}
