import { Component } from '@angular/core';

import { MainComponent } from './main/main.component';
import { BalanceService } from './services/balance/balance.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [MainComponent],
  providers: [BalanceService],
})
export class AppComponent {
  title = 'Tower Admin Dashboard';
}
