import { NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
} from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import {
  MatError,
  MatFormField,
  MatInput,
  MatLabel,
  MatSuffix,
} from '@angular/material/input';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
} from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '../../../services/auth/auth.service';

interface dialogProps {
  fn: () => void;
}

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [
    MatButton,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatIcon,
    MatIconButton,
    MatInput,
    MatRow,
    MatRowDef,
    MatTable,
    MatTooltip,
    NgIf,
    ReactiveFormsModule,
    MatError,
    MatFormField,
    MatLabel,
    MatSuffix,
  ],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss',
})
export class ChangePasswordComponent {
  constructor(
    private authService: AuthService,
    private toastr: ToastrService,

    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: dialogProps,
  ) {}

  public form = this.fb.group({
    oldPassword: ['', [Validators.required]],
    password: ['', [Validators.required]],
    confirmPassword: [
      '',
      [
        Validators.required,
        (control: AbstractControl<string>): ValidationErrors | null => {
          if (!control.value) {
            return null;
          }

          return this.form?.controls.password.value !== control.value
            ? { notMatch: true }
            : null;
        },
      ],
    ],
  });

  hide = true;
  clickEvent(event: MouseEvent) {
    this.hide = !this.hide;
    event.stopPropagation();
  }

  changePassword() {
    if (
      this.form.status === 'VALID' &&
      this.form.controls.password.value &&
      this.form.controls.oldPassword.value
    ) {
      this.authService
        .changePassword({
          newPassword: this.form.controls.password.value,
          oldPassword: this.form.controls.oldPassword.value,
        })
        .subscribe({
          next: () => {
            console.log('success');
          },
          error: (msg) => {
            console.log('ERRORRR', msg);
          },
          complete: () => {
            this.toastr.success('Password changed');
            this.data.fn();
            console.log('it is DONE!');
          },
        });
    }
  }
}
