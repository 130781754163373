import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  BalancePost,
  IApiVersionResp,
  IBalanceItemsList,
  IBalanceResp,
} from '../../models/balance';

@Injectable()
export class BalanceService {
  constructor(private httpClient: HttpClient) {}

  public getApiVersions(): Observable<IApiVersionResp> {
    return this.httpClient.get<IApiVersionResp>('/api/game/api-version');
  }

  public activeBalance(apiVersionId: number, balanceId: number) {
    return this.httpClient.post(
      `/api/game/${apiVersionId}/activate-balance/${balanceId}`,
      {},
    );
  }

  public getBalanceList(apiVersionId: number): Observable<IBalanceItemsList> {
    return this.httpClient.get<IBalanceItemsList>(
      `/api/game/${apiVersionId}/balance`,
    );
  }

  public getBalanceDetails(
    apiVersionId: number,
    balanceId: number,
  ): Observable<IBalanceResp> {
    return this.httpClient.get<IBalanceResp>(
      `/api/game/${apiVersionId}/balance/${balanceId}`,
    );
  }

  public postBalance(params: BalancePost): Observable<BalancePost> {
    return this.httpClient.post<BalancePost>(
      `/api/game/${params.apiVersionId}/balance?activate=${params.activate}&name=${params.balance.name}`,
      params.balance,
    );
  }
}
