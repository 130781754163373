<mat-dialog-content class="mat-typography group-wrapper">
  <div class="login-wrapper">
    <form [formGroup]="form" class="login-container">
      <h5 class="modal-title">Change password</h5>
      <mat-form-field>
        <mat-label>Enter your old password</mat-label>
        <input
          [type]="hide ? 'password' : 'text'"
          formControlName="oldPassword"
          matInput
          placeholder="******"
          required />
        @if (form.controls.oldPassword.invalid) {
          <mat-error>Field is empty</mat-error>
        }
        <button
          mat-icon-button
          matSuffix
          (click)="clickEvent($event)"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide">
          <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Enter your new password</mat-label>
        <input
          [type]="hide ? 'password' : 'text'"
          formControlName="password"
          matInput
          placeholder="******"
          required
          (change)="form.controls.confirmPassword.updateValueAndValidity()" />
        @if (form.controls.password.invalid) {
          <mat-error>Field is empty</mat-error>
        }
        <button
          mat-icon-button
          matSuffix
          (click)="clickEvent($event)"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide">
          <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Confirm password</mat-label>
        <input
          [type]="hide ? 'password' : 'text'"
          formControlName="confirmPassword"
          matInput
          placeholder="******"
          required />
        @if (form.controls.confirmPassword.invalid) {
          <mat-error>Passwords mismatch</mat-error>
        }
        <button
          mat-icon-button
          matSuffix
          (click)="clickEvent($event)"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide">
          <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
      </mat-form-field>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button (click)="changePassword()" class="login-btn" mat-button>
    Change
  </button>
</mat-dialog-actions>
