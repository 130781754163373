<!-- toolbar.component.html -->
<mat-toolbar class="custom-toolbar">
  <button
    mat-icon-button
    class="neumorphic-button example-icon"
    aria-label="Toggle Sidebar"
    (click)="toggleSidebar()">
    <mat-icon>menu</mat-icon>
  </button>
  &nbsp;
  <span>Admin dashboard</span>
  <span class="example-spacer"></span>
  &nbsp;
  <button
    *ngIf="authService.user.email"
    class="admin-menu"
    mat-button
    [matMenuTriggerFor]="menu">
    {{ authService.user.email }}
  </button>
  <mat-menu #menu="matMenu">
    <button (click)="openChangePassword()" mat-menu-item>
      Change password
    </button>
    <button (click)="logout()" mat-menu-item>Logout</button>
  </mat-menu>
</mat-toolbar>
