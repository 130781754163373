import { NgClass, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { HeaderComponent } from '../layout/header/header.component';
import { SidebarComponent } from '../layout/sidenav/sidenav.component';
import { AuthService } from '../services/auth/auth.service';
import { SidebarService } from '../services/sidebar/sidebar.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  standalone: true,
  imports: [HeaderComponent, SidebarComponent, NgClass, RouterOutlet, NgIf],
})
export class MainComponent implements OnInit {
  isSidebarVisible = true;
  constructor(
    private sidebarService: SidebarService,
    public authService: AuthService,
  ) {}

  ngOnInit() {
    this.sidebarService.sidebarVisibility$.subscribe((isVisible) => {
      this.isSidebarVisible = isVisible;
    });
  }
}
